<template>
  <v-card
    class="d-flex flex-column"
    outlined
    elevation="0"
    width="100%"
    :height="height"
    :loading="isLoading || isLoadingChartData"
  >
    <v-row
      no-gutters
      class="shrink"
    >
      <v-col
        class="ma-0 pa-0"
      >
        <v-card-title>
          <div style="min-width: 200px;">
            {{ title }}
          </div>
        </v-card-title>
      </v-col>
      <v-col
        sm="auto"
        align-self="center"
        class="ma-0"
      >
        <v-tabs
          v-model="tab"
          height="32"
          right
        >
          <v-tab>{{ $t('deconve.daily') }}</v-tab>
          <v-tab>{{ $t('deconve.weekly') }}</v-tab>
          <v-tab>{{ $t('deconve.monthly') }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
    >
      <v-col class="pa-1">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <apexchart
              type="bar"
              height="100%"
              :options="dailyChartOptions"
              :series="dailySeries"
            />
          </v-tab-item>
          <v-tab-item>
            <apexchart
              type="bar"
              height="100%"
              :options="weeklyChartOptions"
              :series="weeklySeries"
            />
          </v-tab-item>
          <v-tab-item>
            <apexchart
              type="bar"
              height="100%"
              :options="monthlyChartOptions"
              :series="monthlySeries"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';

export default Vue.extend({
  name: 'ReportCard',
  props: {
    title: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    series: { type: Array, required: true },
    categories: { type: Array, required: true },
    height: { type: String, default: '256px' },
  },
  data() {
    return {
      tab: 0,
      dailySeries: [],
      dailyChartOptions: null,
      monthlySeries: [],
      monthlyChartOptions: null,
      weeklySeries: [],
      weeklyChartOptions: null,
      isLoadingChartData: false,
    };
  },
  computed: {
    weekDayColors() {
      const numberOfDaysByWeek = 7;
      const colors = [];

      const { currentTheme } = this.$vuetify.theme;

      for (let d = 0; d < numberOfDaysByWeek; d += 1) {
        colors.push(currentTheme[`weekDay${d}`]);
      }

      return colors;
    },
  },
  watch: {
    categories() {
      switch (this.tab) {
        case 1:
          this.setWeeklySeries();

          this.dailySeries = [];
          this.monthlySeries = [];
          break;
        case 2:
          this.setMonthlySeries();

          this.dailySeries = [];
          this.weeklySeries = [];
          break;
        default:
          this.setDailySeries();

          this.weeklySeries = [];
          this.monthlySeries = [];
          break;
      }
    },
    tab() {
      switch (this.tab) {
        case 1:
          if (this.weeklySeries.length === 0) this.setWeeklySeries();
          break;
        case 2:
          if (this.monthlySeries.length === 0) this.setMonthlySeries();
          break;
        default:
          if (this.dailySeries.length === 0) this.setDailySeries();
          break;
      }
    },
  },
  created() {
    this.setDailyChartOptions();
    this.setWeeklyChartOptions([]);
    this.setMonthlyChartOptions([]);
  },
  methods: {
    setDailyChartOptions() {
      let categories = [];

      if (this.categories) {
        categories = this.categories.map((date) => this.$moment(date).valueOf());
      }

      this.dailyChartOptions = {
        chart: {
          defaultLocale: this.$i18n.locale,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: this.title,
              },
              svg: {
                filename: this.title,
              },
              png: {
                filename: this.title,
              },
            },
          },
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: [this.$vuetify.theme.currentTheme.borderChart],
          curve: 'smooth',
        },
        xaxis: {
          categories,
          type: 'datetime',
          tooltip: {
            enabled: false,
          },
        },
        tooltip: {
          x: {
            show: true,
            format: 'ddd, dd MMM yyyy',
          },
        },
        fill: {
          opacity: 1,
        },
        colors: [this.$vuetify.theme.currentTheme.reportPrimary],
      };
    },
    setDailySeries() {
      this.dailySeries = [...this.series];
      this.setDailyChartOptions();
    },
    getDayOfWeekLabels() {
      const day = this.$moment();
      const numberOfDaysByWeek = 7;
      const dayOfWeekLabels = new Array(numberOfDaysByWeek);

      for (let i = 0; i < numberOfDaysByWeek; i += 1) {
        const index = day.weekday();
        const label = day.format('ddd');

        dayOfWeekLabels[index] = label;
        day.add(1, 'day');
      }

      return dayOfWeekLabels;
    },
    setMonthlyChartOptions(months) {
      this.monthlyChartOptions = {
        chart: {
          stacked: true,
          defaultLocale: this.$i18n.locale,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: this.title,
              },
              svg: {
                filename: this.title,
              },
              png: {
                filename: this.title,
              },
            },
          },
          zoom: {
            enabled: true,
          },
        },
        colors: this.weekDayColors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: [this.$vuetify.theme.currentTheme.borderChart],
        },
        xaxis: {
          type: 'datetime',
          categories: months,
          tooltip: {
            enabled: false,
          },
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM yyyy',
              day: '',
              hour: '',
              minute: '',
              seconds: '',
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'right',
          horizontalAlign: 'left',
          offsetY: this.$vuetify.breakpoint.xsOnly ? 10 : 30,
        },
        tooltip: {
          x: {
            show: true,
            format: 'MMM yyyy',
          },
        },
      };
    },
    setMonthlySeries() {
      this.isLoadingChartData = true;

      return new Promise((resolve) => {
        const numberOfDaysByWeek = 7;
        const months = [];
        const series = [];

        const dataByWeekDay = Array.from({ length: numberOfDaysByWeek }, () => []);

        if (this.series && this.series.length > 0) {
          const [{ data }] = this.series;
          const monthlyDataByWeekDay = Array.from({ length: numberOfDaysByWeek }, () => ({}));

          this.categories.forEach((date, index) => {
            const day = this.$moment(date);

            const dayOfWeek = day.weekday();
            const month = day.startOf('month').valueOf();
            const dataByMonth = monthlyDataByWeekDay[dayOfWeek];

            if (month in dataByMonth) {
              dataByMonth[month] += data[index];
            } else {
              dataByMonth[month] = data[index];
            }

            if (!months.includes(month)) months.push(month);
          });

          months.forEach((month) => {
            monthlyDataByWeekDay.forEach((dataByMonth, index) => {
              dataByWeekDay[index].push(month in dataByMonth ? dataByMonth[month] : 0);
            });
          });
        }

        if (months.length === 0) {
          // To show the chart legend and avoid invalid categories
          dataByWeekDay.forEach((dataByWeek) => dataByWeek.push(0));
        }

        const dayOfWeekLabels = this.getDayOfWeekLabels();

        dayOfWeekLabels.forEach((dayOfWeek, index) => {
          series.push({ data: dataByWeekDay[index], name: dayOfWeek });
        });

        this.monthlySeries = series;
        this.setMonthlyChartOptions(months);

        this.isLoadingChartData = false;
        resolve();
      });
    },
    setWeeklyChartOptions(weeks) {
      // TODO: we should use labels and tooltip formatter function to show only the first and last
      // day of each week, but it shows an error when we try to use it:
      // formatter: function (value, timestamp, opts) {
      //   return opts.dateFormarter(new Date(timestamp)).format('dd MMM yyyy');
      // }
      // For now, we will let the default formatter

      this.weeklyChartOptions = {
        chart: {
          stacked: true,
          defaultLocale: this.$i18n.locale,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: this.title,
              },
              svg: {
                filename: this.title,
              },
              png: {
                filename: this.title,
              },
            },
          },
          zoom: {
            enabled: true,
          },
        },
        colors: this.weekDayColors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: [this.$vuetify.theme.currentTheme.borderChart],
        },
        xaxis: {
          type: 'datetime',
          categories: weeks,
          tooltip: {
            enabled: false,
          },
          labels: {
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMM yyyy',
              day: 'dd MMM',
              hour: '',
              minute: '',
              seconds: '',
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'right',
          horizontalAlign: 'left',
          offsetY: this.$vuetify.breakpoint.xsOnly ? 10 : 30,
        },
        tooltip: {
          x: {
            show: true,
            format: 'dd MMM yyyy',
          },
        },
      };
    },
    setWeeklySeries() {
      this.isLoadingChartData = true;

      return new Promise((resolve) => {
        const numberOfDaysByWeek = 7;
        const weeks = [];
        const series = [];
        const dataByWeekDay = Array.from({ length: numberOfDaysByWeek }, () => []);

        if (this.series && this.series.length > 0) {
          const [{ data }] = this.series;

          const totalDataByWeekDay = Array.from({ length: numberOfDaysByWeek }, () => ({}));

          this.categories.forEach((date, index) => {
            const day = this.$moment(date);
            const dayOfWeek = day.weekday();
            const weekLabel = day.startOf('week').valueOf();
            const dataByWeek = totalDataByWeekDay[dayOfWeek];

            if (weekLabel in dataByWeek) {
              dataByWeek[weekLabel] += data[index];
            } else {
              dataByWeek[weekLabel] = data[index];
            }

            if (!weeks.includes(weekLabel)) weeks.push(weekLabel);
          });

          weeks.forEach((weekLabel) => {
            totalDataByWeekDay.forEach((dataByWeek, index) => {
              dataByWeekDay[index].push(weekLabel in dataByWeek ? dataByWeek[weekLabel] : 0);
            });
          });
        }

        if (weeks.length === 0) {
          // To show the chart legend and avoid invalid categories
          dataByWeekDay.forEach((dataByWeek) => dataByWeek.push(0));
        }

        const dayOfWeekLabels = this.getDayOfWeekLabels();

        dayOfWeekLabels.forEach((dayOfWeek, index) => {
          series.push({ data: dataByWeekDay[index], name: dayOfWeek });
        });

        this.weeklySeries = series;
        this.setWeeklyChartOptions(weeks);

        this.isLoadingChartData = false;
        resolve();
      });
    },
  },
});
</script>

<style scoped>
.v-window {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.v-window-item {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
