<template>
  <v-container
    class="pa-0 d-flex"
    style="height: 100%; max-width: 1260px;"
    fluid
  >
    <div
      class="pa-2"
      style="width: 100%;"
    >
      <v-row no-gutters>
        <v-col
          class="pa-1"
          style="min-width: 200px; max-width: 520px;"
        >
          <v-select
            v-model="selectedVideoIds"
            dense
            outlined
            item-text="name"
            item-value="id"
            multiple
            hide-details
            :loading="isLoadingVideos"
            :items="videos"
            :label="$t('deconve.videos')"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.name }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                {{ otherVideoSelectedText }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          class="pa-1"
          sm="auto"
          align-self="center"
        >
          <div class="d-flex flex-row">
            <date-filter
              width="100%"
              input-class="mr-2"
              :start-date="startDate"
              :end-date="endDate"
              @change-start-date="changeStartDate"
              @change-end-date="changeEndDate"
            />
            <rectangle-button
              icon="mdi-download"
              outlined
              color="primary"
              :is-loading="isDownloadReport"
              @clicked="exportReports"
            >
              <div class="d-none d-md-flex">
                {{ $t('deconve.exportReport') }}
              </div>
            </rectangle-button>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
      >
        <v-col
          cols="12"
          md="2"
          sm="3"
          class="pa-1"
        >
          <total-card
            height="100%"
            :is-loading="isLoadingReportByDay"
            :subtitle="$t('deconve.peopleDetected')"
            :total="totalNumberOfPeople"
          />
        </v-col>
        <v-col
          class="pa-1"
          cols="12"
          md="10"
          sm="9"
        >
          <report-card
            height="320px"
            :title="$t('deconve.peopleFlow')"
            :is-loading="isLoadingReportByDay"
            :series="reportByDayChartSeries"
            :categories="reportByDayChartLabels"
          />
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ma-0"
      >
        <v-col
          class="pa-1"
          cols="12"
          md="6"
          sm="12"
        >
          <total-by-video-card
            height="280px"
            :title="$t('deconve.totalByVideo')"
            :is-loading="isLoadingReportByDay"
            :series="totalByVideoChartSeries"
            :categories="totalByVideoChartLabels"
          />
        </v-col>
        <v-col
          class="pa-1"
          cols="12"
          md="6"
          sm="12"
        >
          <hourly-heat-map-card
            height="280px"
            :title="$t('deconve.hourlyHeatMapTitle')"
            :is-loading="isLoadingReportByHour"
            :series="reportByHourChartSeries"
            :categories="reportByHourChartLabels"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import DateFilter from '@/components/DateFilter.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import HourlyHeatMapCard from './HourlyHeatMapCard.vue';
import ReportCard from './ReportCard.vue';
import TotalByVideoCard from './TotalByVideoCard.vue';
import TotalCard from './TotalCard.vue';

export default Vue.extend({
  name: 'PeopleFlow',
  components: {
    DateFilter,
    HourlyHeatMapCard,
    RectangleButton,
    ReportCard,
    TotalCard,
    TotalByVideoCard,
  },
  data: () => ({
    startDate: '',
    endDate: '',
    selectedVideoIds: [],
    isDownloadReport: false,
    isLoadingVideos: true,
    isLoadingReportByDay: true,
    isLoadingReportByHour: true,
    reportByDayChartSeries: [],
    reportByDayChartLabels: [],
    totalByVideoChartSeries: [],
    totalByVideoChartLabels: [],
    reportByHourChartSeries: [],
    reportByHourChartLabels: [],
    updateReportTimer: '',
  }
  ),
  computed: {
    ...mapGetters({
      videos: 'peoplecounter/getVideos',
      reportByDayToChartData: 'peoplecounter/reportByDayToChartData',
      reportByHourToChartData: 'peoplecounter/reportByHourToChartData',
      totalNumberOfPeople: 'peoplecounter/totalNumberOfPeople',
      totalNumberOfPeopleByVideo: 'peoplecounter/totalNumberOfPeopleByVideo',
    }),
    otherVideoSelectedText() {
      const count = this.selectedVideoIds.length - 1;

      return this.$tc('deconve.otherItems', count, { count });
    },
  },
  watch: {
    selectedVideoIds() {
      this.updateReports();
    },
    reportByDayToChartData() {
      const { labels, data } = this.reportByDayToChartData;

      this.reportByDayChartLabels = labels;
      this.reportByDayChartSeries = [{ data, name: this.$t('deconve.people') }];
    },
    reportByHourToChartData() {
      const { labels, data } = this.reportByHourToChartData;

      this.reportByHourChartLabels = labels;
      this.reportByHourChartSeries = [{ data, name: this.$t('deconve.people') }];
    },
    totalNumberOfPeopleByVideo() {
      const { labels, data } = this.totalNumberOfPeopleByVideo;

      this.totalByVideoChartLabels = labels;
      this.totalByVideoChartSeries = [{ data, name: this.$t('deconve.people') }];
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);
    this.initDateRange();
    this.fetchReports();
    this.setTimerToUpdateReports();
  },
  beforeDestroy() {
    clearInterval(this.updateReportTimer);
  },
  methods: {
    ...mapActions({
      downloadReportByHour: 'peoplecounter/downloadReportByHour',
      fetchVideos: 'peoplecounter/fetchVideos',
      fetchReportByDay: 'peoplecounter/fetchReportByDay',
      fetchReportByHour: 'peoplecounter/fetchReportByHour',
    }),
    initDateRange() {
      const oneMonthBefore = this.$moment().subtract(1, 'month');

      this.startDate = oneMonthBefore.startOf('day').toISOString();
      this.endDate = this.$moment().endOf('day').toISOString();
    },
    exportReports() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.selectedVideoIds.length > 0) {
        params.videoIds = this.selectedVideoIds;
      }

      this.isDownloadReport = true;
      this.downloadReportByHour(params).then(() => {
        this.isDownloadReport = false;
      }).catch(() => {
        this.isDownloadReport = false;
      });
    },
    updateReportByDay(params) {
      this.isLoadingReportByDay = true;

      this.fetchReportByDay(params).then(() => {
        this.isLoadingReportByDay = false;
      }).catch(() => {
        this.isLoadingReportByDay = false;
      });
    },
    updateReportByHour(params) {
      this.isLoadingReportByHour = true;

      this.fetchReportByHour(params).then(() => {
        this.isLoadingReportByHour = false;
      }).catch(() => {
        this.isLoadingReportByHour = false;
      });
    },
    updateReports() {
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
      };

      if (this.selectedVideoIds.length > 0) {
        params.videoIds = this.selectedVideoIds;
      }

      this.updateReportByDay(params);
      this.updateReportByHour(params);
    },
    fetchReports() {
      this.isLoadingVideos = true;

      this.fetchVideos().then(() => {
        this.isLoadingVideos = false;
        this.updateReports();
      }).catch(() => {
        this.isLoadingVideos = false;
      });
    },
    changeStartDate(newDate) {
      this.startDate = this.$moment(newDate).startOf('day').toISOString();
      this.updateReports();
    },
    changeEndDate(newDate) {
      this.endDate = this.$moment(newDate).endOf('day').toISOString();
      this.updateReports();
    },
    setTimerToUpdateReports() {
      const fiveMinutes = 5 * 60 * 1000;

      this.updateReportTimer = setInterval(this.fetchReports, fiveMinutes);
    },
  },
});
</script>
