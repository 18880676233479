<template>
  <v-card
    class="d-flex flex-column"
    outlined
    width="100%"
    elevation="0"
    :height="height"
    :loading="isLoading"
  >
    <v-card-title class="pr-1">
      <div>
        {{ title }}
      </div>
      <v-spacer />
      <v-btn
        icon
        color="primary"
        @click="isDescendingOrder = !isDescendingOrder"
      >
        <v-icon>
          {{ isDescendingOrder ? icons.mdiArrowUp : icons.mdiArrowDown }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle v-if="numberOfItems > maxItems">
      {{ isDescendingOrder ? $t('deconve.videosWithHighestFlow', [maxItems])
        : $t('deconve.videosWithLowestFlow', [maxItems]) }}
    </v-card-subtitle>
    <v-row
      class="ma-0"
    >
      <v-col class="ma-0 pa-1">
        <apexchart
          type="bar"
          height="100%"
          :options="chartOptions"
          :series="chartSeries"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';

import { mdiArrowDown, mdiArrowUp } from '@mdi/js';

export default Vue.extend({
  name: 'TotalByVideo',
  props: {
    title: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    series: { type: Array, required: true },
    categories: { type: Array, required: true },
    height: { type: String, default: '256px' },
    maxItems: { type: Number, default: 10 },
  },
  data() {
    return {
      isDescendingOrder: true,
      icons: { mdiArrowDown, mdiArrowUp },
    };
  },
  computed: {
    numberOfItems() {
      if (this.series.length > 0) {
        const [{ data }] = this.series;

        return data.length;
      }

      return 0;
    },
    chartSeries() {
      if (this.series.length > 0) {
        const [{ data, name }] = this.series;

        if (this.isDescendingOrder) {
          return [{ name, data: data.slice(0, this.maxItems) }];
        }

        return [{ name, data: data.slice(-this.maxItems).reverse() }];
      }

      return [];
    },
    chartOptions() {
      const selectedCategories = this.isDescendingOrder ? (
        this.categories.slice(0, this.maxItems)) : this.categories.slice(-this.maxItems).reverse();

      return {
        chart: {
          defaultLocale: this.$i18n.locale,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: this.title,
              },
              svg: {
                filename: this.title,
              },
              png: {
                filename: this.title,
              },
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: selectedCategories,
        },
        colors: [this.$vuetify.theme.currentTheme.reportPrimary],
      };
    },
  },
});
</script>
