<template>
  <v-card
    outlined
    min-height="200px"
    :height="height"
    :loading="isLoading"
  >
    <v-card-title>{{ $t('deconve.total') }}</v-card-title>
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text
      v-if="!isLoading"
      class="subtitle-1 text-h4 font-weight-bold textBlack--text mb-3"
    >
      {{ total }}
    </v-card-text>
  </v-card>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';

export default Vue.extend({
  name: 'TotalCard',
  props: {
    isLoading: { type: Boolean, default: false },
    total: { type: Number, default: 0 },
    subtitle: { type: String, default: '' },
    height: { type: String, default: '256px' },
  },
});
</script>
