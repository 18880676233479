<template>
  <v-card
    class="d-flex flex-column"
    outlined
    width="100%"
    elevation="0"
    :height="height"
    :loading="isLoading"
  >
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-row
      class="ma-0"
    >
      <v-col class="ma-0 pa-1">
        <apexchart
          type="heatmap"
          height="100%"
          :options="chartOptions"
          :series="heatMapSeries"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';

export default Vue.extend({
  name: 'HourlyHeatMapCard',
  props: {
    title: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    series: { type: Array, required: true },
    categories: { type: Array, required: true },
    height: { type: String, default: '256px' },
  },
  data() {
    return {
      heatMapSeries: [],
      maxSerieValue: 0,
    };
  },
  computed: {
    chartOptions() {
      const numberOfHoursByDay = 24;
      const categories = Array.from(Array(numberOfHoursByDay).keys());

      return {
        chart: {
          defaultLocale: this.$i18n.locale,
          toolbar: {
            show: true,
            export: {
              csv: {
                filename: this.title,
              },
              svg: {
                filename: this.title,
              },
              png: {
                filename: this.title,
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 1,
        },
        xaxis: {
          categories,
          tooltip: {
            enabled: false,
          },
          tickAmount: this.$vuetify.breakpoint.xsOnly ? 12 : undefined,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'right',
          horizontalAlign: 'left',
          markers: {
            radius: 0,
          },
        },
        tooltip: {
          x: {
            show: true,
            formatter: (value) => this.$tc('deconve.hourLabel', value),
          },
        },
        colors: [this.$vuetify.theme.currentTheme.heatMap],
        plotOptions: {
          heatmap: {
            radius: 0,
          },
        },

      };
    },
  },
  watch: {
    categories() {
      this.setHeatMapSeries();
    },
  },
  created() {
    this.setHeatMapSeries();
  },
  methods: {
    getDayOfWeekLabels() {
      const day = this.$moment();
      const numberOfDaysByWeek = 7;
      const dayOfWeekLabels = new Array(numberOfDaysByWeek);

      for (let i = 0; i < numberOfDaysByWeek; i += 1) {
        const index = day.weekday();
        const label = day.format('ddd');

        dayOfWeekLabels[index] = label;
        day.add(1, 'day');
      }

      return dayOfWeekLabels;
    },
    setHeatMapSeries() {
      this.isLoadingChartData = true;

      return new Promise((resolve) => {
        const numberOfDaysByWeek = 7;
        const numberOfHoursByDay = 24;

        const dataByWeekDay = Array.from(
          { length: numberOfDaysByWeek },
          () => Array.from({ length: numberOfHoursByDay }, () => 0),
        );

        if (this.series && this.series.length > 0) {
          const [{ data }] = this.series;

          this.categories.forEach((date, index) => {
            const day = this.$moment(date);

            const dayOfWeek = day.weekday();
            const hour = day.hour();
            const value = data[index];

            dataByWeekDay[dayOfWeek][hour] += value;
          });
        }

        const dayOfWeekLabels = this.getDayOfWeekLabels();
        let maxSerieValue = 0;
        const series = new Array(numberOfDaysByWeek);

        dayOfWeekLabels.forEach((dayOfWeek, index) => {
          const serieIndex = numberOfDaysByWeek - 1 - index;
          const data = dataByWeekDay[index];
          const maxValue = Math.max(...data);

          if (maxValue > maxSerieValue) maxSerieValue = maxValue;

          series[serieIndex] = { data, name: dayOfWeek };
        });

        this.maxSerieValue = maxSerieValue;
        this.heatMapSeries = series;
        this.isLoadingChartData = false;
        resolve();
      });
    },
  },
});
</script>
