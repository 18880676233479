<template>
  <div
    :style="{
      borderColor: activeBorderColor || $vuetify.theme.themes.light.border,
      backgroundColor: $vuetify.theme.themes.light.inputBackground,
      height: $vuetify.breakpoint.mobile? '36px' : '40px',
      width
    }"
    class="input-container"
    :class="inputClass"
  >
    <div
      class="icon-content"
    >
      <v-icon
        :small="$vuetify.breakpoint.mobile"
        :color="activeIconColor"
      >
        mdi-calendar-outline
      </v-icon>
    </div>
    <v-menu
      ref="startDateModal"
      v-model="startDateModal"
      offset-y
      transition="slide-y-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          :class="startDateModal? 'date-input primary' : 'date-input grey lighten-5'"
          :small="$vuetify.breakpoint.mobile"
          v-bind="attrs"
          v-on="on"
        >
          {{ startDatePicker | moment('DD/MM/YYYY') }}
        </v-btn>
      </template>
      <v-date-picker
        v-model="startDatePicker"
        color="primary"
        :max="endDatePicker"
        :locale="$i18n.locale"
      />
    </v-menu>
    <div
      class="date-range-divisor"
      :class="startDateModal || endDateModal? 'primary': 'neutral'"
    />
    <v-menu
      ref="endDateModal"
      v-model="endDateModal"
      transition="slide-y-transition"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          :class="endDateModal? 'date-input primary' : 'date-input grey lighten-5'"
          :small="$vuetify.breakpoint.mobile"
          v-bind="attrs"
          v-on="on"
        >
          {{ endDatePicker | moment('DD/MM/YYYY') }}
        </v-btn>
      </template>
      <v-date-picker
        v-model="endDatePicker"
        color="primary"
        :min="startDatePicker"
        :locale="$i18n.locale"
      />
    </v-menu>
  </div>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

export default {
  name: 'DateFilter',
  props: {
    placeholder: { type: String, default: '' },
    inputClass: { type: String, default: '' },
    options: { type: Array, default: null },
    width: { type: String, default: '' },
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
  },
  data: () => ({
    startDateModal: false,
    endDateModal: false,
  }),
  computed: {
    activeBorderColor() {
      return this.startDateModal || this.endDateModal
        ? this.$vuetify.theme.themes.light.primary
        : this.$vuetify.theme.themes.light.border;
    },
    activeIconColor() {
      return this.startDateModal || this.endDateModal
        ? this.$vuetify.theme.themes.light.primary
        : this.$vuetify.theme.themes.light.neutral;
    },
    startDatePicker: {
      get() {
        return this.dateToIsoFormat(new Date(this.startDate));
      },
      set(newDate) {
        this.$emit('change-start-date', this.$moment(newDate).toISOString());
      },
    },
    endDatePicker: {
      get() {
        return this.dateToIsoFormat(new Date(this.endDate));
      },
      set(newDate) {
        this.$emit('change-end-date', this.$moment(newDate).toISOString());
      },
    },
  },
  methods: {
    dateToIsoFormat(date) {
      // v-date-picker expects date in YYYY-MM-DD format. Because of the user locale, we can not
      // 'just use' ISOFormat
      let month = `${date.getMonth() + 1}`;
      let day = `${date.getDate()}`;
      const year = date.getFullYear();

      if (month.length < 2) { month = `0${month}`; }

      if (day.length < 2) { day = `0${day}`; }

      return [year, month, day].join('-');
    },
  },
};
</script>

<style scoped>
  .input-container{
    display: flex;
    width:fit-content;
    height: 44px;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    border: 1px solid black;
  }

  .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-left: 12px;
  }

  .date-input {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
  }

  .date-range-divisor {
    height: 1px;
    width: 6px;
  }
</style>
